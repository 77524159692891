import React, { useEffect, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Dialog from '../../../components/functional/SweetAlert';
import { navigateTo } from '../../../components/functional/Navigate';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { purchasePackage, successfullPurchase } from '../../../shared-state/main';
import { VozziPrimaryButton } from '../../../components/layout/_/button.component';
import { theme } from '../../../layouts/main.layout';
import { StripeFormLoader } from './StripeFormLoader';
import { logError } from '../services/logErrors';
import { APIs, COUNTRY } from '../../../configs/env';
import { weAreRunningInBrowser } from '../../../utils/weAreRunningInBrowser';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';

export const CheckoutForm = (props: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const bundleDetails = useRecoilValue(purchasePackage);
  const [user, setUser] = useRecoilState(successfullPurchase);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [errorCardNumber, setErrorCardNumber] = useState('');
  const [errorCardExpiry, setErrorCardExpiry] = useState('');
  const [errorCardCvc, setErrorCardCvc] = useState('');
  const [errorGeoLocation, setErrorGeoLocation] = useState('');

  useEffect(() => {
    if (weAreRunningInBrowser()) {
      gTagDataLayerPush({
        event: 'web_shop_2nd_step',
        country: COUNTRY,
        bundleKeyName: bundleDetails.bundleKeyName,
      });
    }
  }, []);

  useEffect(() => {
    setUser({ email: props.user.userEmail, mobileNumber: props.user.userMobileNumber });
  }, []);

  const checkIfPaymentSucceeded = async (chargeId: string, paymentMethodId: string) => {
    await axios
      .get(`${APIs.mainServerApiUrl}/v1/payment/stripe/web-shop/charges/${chargeId}`)
      .then(async (response: any) => {
        if (response.status === 'succeeded') {
          // if everything is ok, we call last route to create our user.
          const dataToSend = props.user;
          dataToSend.paymentMethodId = paymentMethodId;

          try {
            const resp = await axios.post(
              `${APIs.mainServerApiUrl}/v1/payment/stripe/web-shop/charges/${chargeId}/client-bundles`,
              dataToSend
            );
          } catch (err: any) {
            console.error(err);
            logError('Payment SUCCEEDED', err.message, true, user);
          }

          gTagDataLayerPush({
            event: 'web_shop_finished',
            price: bundleDetails.packagePrice,
            country: COUNTRY,
            bundleKeyName: bundleDetails.bundleKeyName,
          });

          // after all is done, we redirect user to successfull purchase page
          navigateTo(t('successfullPurchaseThankYouPageLink'));
        }
      })
      .catch((error: any) => {
        logError('checkIfPaymentSucceeded', error.message, true, user);
        console.log(error);

        // if payment not succeeded, we call api until it succeed.
        //checkIfPaymentSucceeded(chargeId);
      });
  };

  const handleSubmit = async (event: any) => {
    setBtnDisabled(true);
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    gTagDataLayerPush({
      event: 'web_shop_2nd_step_btn_click',
      country: COUNTRY,
      bundleKeyName: bundleDetails.bundleKeyName,
    });

    const { error, paymentMethod }: any = await stripe!.createPaymentMethod({
      type: 'card',
      card: elements!.getElement(CardNumberElement),
    });

    if (!error && props.user.lat !== null && props.user.lat !== undefined) {
      try {
        const { id } = paymentMethod; // when we have paymentMethodId and bundleId, we call our api to make a stripe charge
        if (typeof navigator !== 'undefined') {
          navigator.geolocation.getCurrentPosition(
            async (success: any) => {
              if (success) {
                await axios
                  .post(`${APIs.mainServerApiUrl}/v1/payment/stripe/web-shop/charges/bundles/${bundleDetails.bundle.id}`, {
                    paymentMethodId: id,
                    lat: props.user.lat,
                    lng: props.user.lng,
                  })
                  .then((response: any) => {
                    const chargeId = response.id; // when we have chargeId, we call second route to determine if payment succeeded
                    checkIfPaymentSucceeded(chargeId, id);
                  })
                  .catch((error: any) => {
                    let message;
                    switch (error.message) {
                      case 'Your card has insufficient funds.':
                        message = t('stripeInsufficientFunds');
                        break;
                      case 'Your card was declined.':
                        message = t('stripeCardDeclined');
                        break;
                      case 'Your card has expired.':
                        message = t('stripeCardExpired');
                        break;
                      case "Your card's security code is incorrect.":
                        message = t('stripeInvalidCVV');
                        break;
                      default:
                        message = t('stripeSomethingWentWrong');
                    }

                    if (error.errors.statusCode === 417 && error.errors.key === 'ERROR_DURING_LOCATION_CHECK') {
                      message = t('purchasePageCantBuyPackageFromAnotherCountry');
                    }

                    setLoading(false);
                    setBtnDisabled(false);
                    Dialog.fire({
                      title: message,
                      icon: 'error',
                      confirmButtonColor: theme.colors.vozziVividOrange,
                    });

                    console.log('Something went wrong');
                  });
              }
            },
            async (error: any) => {
              if ((await error.message) === 'User denied Geolocation') {
                Dialog.fire({
                  title: t('purchasePageGeolocationEnableText'),
                  icon: 'error',
                  confirmButtonColor: theme.colors.vozziVividOrange,
                });
                setLoading(false);
                setBtnDisabled(false);
              }
            },
            [{ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }]
          );
        }
      } catch (error: any) {
        logError('handleSubmit', error.message, false);
      }
    } else {
      logError('handleSubmit 2', error.message, false);
      if (error && error.code) {
        if (error.code === 'incomplete_number') {
          setErrorCardNumber(t('stripeCardInvalid'));
        }
        if (error.code === 'invalid_number') {
          setErrorCardNumber(t('stripeCardInvalid'));
        }
        if (error.code === 'incomplete_expiry') {
          setErrorCardExpiry(t('stripeCardExpiryDateInvalid'));
        }
        if (error.code === 'incomplete_cvc') {
          setErrorCardCvc(t('stripeCardCVCInvalid'));
        }

        if (props.user.lat === undefined || props.user.lat === null) {
          setErrorGeoLocation(t('purchasePageModalGeolocation'));
        }

        setLoading(false);
        setBtnDisabled(false);
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        className="overlay"
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          backgroundColor: loading ? 'rgba(255,255,255,.4)' : 'transparent',
          zIndex: loading ? 99 : -1,
          position: loading ? 'absolute' : 'static',
        }}
      >
        <StripeFormLoader style={{ height: 40, visibility: loading ? 'visible' : 'hidden' }} />
        <span style={{ color: 'red', fontSize: 10 }}>{errorGeoLocation}</span>
      </div>
      <form onSubmit={handleSubmit} style={{ paddingLeft: 5 }}>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <label style={{ width: '100%', color: '#3b3b3c' }}>
            {t('stripeCardNumberLabel')}
            <CardNumberElement />
          </label>
          <span style={{ color: 'red', fontSize: 10 }}>{errorCardNumber}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          <div style={{ width: '48.5%', textAlign: 'left' }}>
            <label style={{ color: '#3b3b3c' }}>
              {t('stripeExpirationDateLabel')}
              <CardExpiryElement />
              <span style={{ color: 'red', fontSize: 10 }}>{errorCardExpiry}</span>
            </label>
          </div>
          <div style={{ width: '48.5%', textAlign: 'left' }}>
            <label style={{ color: '#3b3b3c' }}>
              CVC
              <CardCvcElement />
              <span style={{ color: 'red', fontSize: 10 }}>{errorCardCvc}</span>
            </label>
          </div>
        </div>

        <VozziPrimaryButton
          style={{
            display: 'block',
            width: '100%',
            marginTop: 30,
            opacity: loading ? 0.5 : 1,
            cursor: btnDisabled ? 'not-allowed' : 'pointer',
          }}
          disabled={btnDisabled}
        >
          {t('stripePayButtonLabel')}
        </VozziPrimaryButton>
      </form>
    </div>
  );
};
