import axios from 'axios';
// import { logPayment } from '../../wbshop/pages/3/services/logPayment.service';

/**
 * Toggle the UI error state (with message) & log the error.
 * @param {string} message
 * @param {any} additionalErrorData - Anything additonal, be it Error object, simple object, array, or string.
 * @param {boolean} critical - Is error CRITICAL
 * @param {any} userDataFrom - User FORM data
 * @param {any} wholeWebshopState - WEBSHOP state
 */
export const logError = (
  message: string,
  additionalErrorData?: any,
  critical = false,
  userDataFrom?: any,
  wholeWebshopState?: any
): void => {
  const qParams = {
    error: 'true',
    state: JSON.stringify(userDataFrom),
    errorMessage: message,
    critical: critical ? 'true' : 'false',
  };

  axios.get('/webshop/log', { params: qParams });
  // logPayment({
  //   error: true,
  //   errorMessage: message || 'No error message',
  //   errorAdditionalData: additionalErrorData || null,
  //   critical: critical,
  //   initFormData: userDataFrom,
  //   ...wholeWebshopState,
  // });
};
