import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import { useRecoilState } from 'recoil';
import { COUNTRY } from '../../../configs/env';
import { bundlePaySlip, purchasePackage, webshopUser } from '../../../shared-state/main';
import { browserName } from 'react-device-detect';

import { useTranslation } from 'react-i18next';
import { boolean, object, string } from 'yup';
import { VozziPrimaryButton } from '../../../components/layout/_/button.component';
import Dialog from '../../../components/functional/SweetAlert';
import { CustomErrorMessage } from '../../../components/functional/CustomErrorMessage';
import { theme } from '../../../layouts/main.layout';
import './style.css';
import { CheckoutForm } from './StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AllSecureForm from './AllSecureForm';
import { navigateTo } from '../../../components/functional/Navigate';

import { logError } from '../services/logErrors';
import { Spinner } from '../../wbshop/components/Spinner/Spinner.component';
import { weAreRunningInBrowser } from '../../../utils/weAreRunningInBrowser';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';

const inputStyle = {
  border: `solid 0.5px #ddd`,
  borderRadius: '8px',
  height: '45px',
  backgroundColor: `white`,
  marginTop: '10px',
  display: 'block',
  padding: '0px 0px 0px 10px',
  width: '100%',
};

const editButton = {
  backgroundColor: 'white',
  padding: '10px 15px',
  border: '1px solid gray',
  height: '40px',
  borderRadius: '6px',
  lineHeight: '20px',
  cursor: 'pointer',
};

const MultiStepper = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [user, setUser] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    lat: null,
    lng: null,
    terms: false,
  });
  const [legalEntity, setLegalEntity] = React.useState(false);
  const [pibNumber, setPibNumber] = React.useState('');
  const [geolocationPosition, setGeolocationPosition] = React.useState({} as any);
  const [bundleDetails, setBundleDetails] = useRecoilState(purchasePackage);
  const [bundle, setBundle] = useRecoilState(bundlePaySlip);
  const [_payslipUser, setPayslipUser] = useRecoilState(webshopUser);
  const [allSecureHostUrl, setAllSecureHostUrl] = React.useState(null);
  const [checkoutId, setCheckoutId] = React.useState(null);
  const [stripePromise, setStripePromise] = React.useState(null) as any;
  const [geolocationConfirmation, setGeolocationConfirmation] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const { t, i18n } = useTranslation();

  const [loadingText, setLoadingText] = React.useState(t('purchasePageLoadingText'));

  const LANG = i18n.language;

  const handlePermission = () => {
    if (typeof navigator !== 'undefined') {
      navigator.geolocation.getCurrentPosition(
        async (success: any) => {
          setGeolocationPosition({ lat: await success.coords.latitude, lng: await success.coords.longitude });
          if (!geolocationConfirmation) {
            setGeolocationConfirmation(true);
          }
        },
        async (error: any) => {
          if ((await error.message) === 'User denied Geolocation') {
            setLoadingText(t('purchasePageLoadingMustApproveGeolocationText'));
            setTimeout(() => {
              Dialog.fire({
                title: t('purchasePageModalGeolocation'),
                icon: 'error',
                confirmButtonColor: theme.colors.vozziVividOrange,
                preConfirm: () => {
                  window.history.back();
                },
              });
            }, 800);
          }
        }
      );
    }

    // if user uses Facebook or Instagram internal browser then detect his location with ip api
    if (browserName === 'Facebook' || browserName === 'Instagram') {
      getIpLocation().then((response: any) => {
        setGeolocationPosition({ lat: response.data.latitude, lng: response.data.longitude });
        setPayslipUser({
          ..._payslipUser,
          lat: response.data.latitude,
          lng: response.data.longitude,
        });
        if (!geolocationConfirmation) {
          setGeolocationConfirmation(true);
        }

        getAllSecureData(bundleDetails.bundle.id, response.data.latitude, response.data.longitude)
          .then((response: any) => {
            setCheckoutId(response.data.data.checkoutId);
            setAllSecureHostUrl(response.data.data.allSecureHostUrl);

            if (weAreRunningInBrowser()) {
              localStorage.setItem('bundleId', bundleDetails.bundle.id);
            }
          })
          .catch((error: any) => {
            logError(`getAllSecureData. Error message: ${error.message || 'no err msg'}`, null, false);
            if (error) {
              Dialog.fire({
                title: t('purchasePageCantBuyPackageFromAnotherCountry'),
                icon: 'error',
                confirmButtonColor: theme.colors.vozziVividOrange,
                preConfirm: () => {
                  navigateTo(t('link_webShop'));
                },
              });
            }
          });
      });
    }
  };

  const getIpLocation = async () => {
    return await axios.get(`https://ipapi.co/json/`);
  };

  // we call api to get allsecure checkoutId (form data)
  const getAllSecureData = async (bundleId: string, lat: number | null, lng: number | null) => {
    return await axios.get(`/allsecure/get-form-data-for-webshop?bundleId=${bundleId}&lat=${lat}&lng=${lng}`);
  };

  React.useEffect(() => {
    if (weAreRunningInBrowser() && typeof navigator !== 'undefined') {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(async function (position) {
          setGeolocationPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
          setPayslipUser({
            ..._payslipUser,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          if (!geolocationConfirmation) {
            setGeolocationConfirmation(true);
          }
          if (COUNTRY === 'rs' && bundleDetails.bundle !== null) {
            getAllSecureData(bundleDetails.bundle.id, position.coords.latitude, position.coords.longitude)
              .then((response: any) => {
                setCheckoutId(response.data.data.checkoutId);
                setAllSecureHostUrl(response.data.data.allSecureHostUrl);

                localStorage.setItem('bundleId', bundleDetails.bundle.id);
                localStorage.setItem('vozziApiCallbackUrl', response.data.data.vozziApiCallbackUrl);
                localStorage.setItem('bundleKeyName', bundleDetails.bundleKeyName!);
              })
              .catch((error: any) => {
                logError(`getAllSecureData. Error message: ${error.message || 'no err msg'}`, null, false);
                if (error) {
                  Dialog.fire({
                    title: t('purchasePageCantBuyPackageFromAnotherCountry'),
                    icon: 'error',
                    confirmButtonColor: theme.colors.vozziVividOrange,
                    preConfirm: () => {
                      navigateTo(t('link_webShop'));
                    },
                  });
                }
              });
          } else {
            setStripePromise(loadStripe(`${process.env.GATSBY_VOZZI_STRIPE_PUBLISHABLE_KEY}`));
          }
        });
      } else {
        console.log('Geolocation blocked by user or browser');
      }
    }

    handlePermission();
    return () => {
      setGeolocationPosition({});
      setCheckoutId(null);
      setAllSecureHostUrl(null);
      setGeolocationPosition(false);
    };
  }, [geolocationConfirmation]);

  let regex = /^38[0-9a-z](\/?[0-9a-z])*\/?$/;

  if (LANG === 'bg') {
    regex = /^359[0-9a-z](\/?[0-9a-z])*\/?$/;
  }

  if (LANG === 'ro') {
    regex = /^40[0-9a-z](\/?[0-9a-z])*\/?$/;
  }

  const validationSchema = object().shape({
    firstName: string().required(t('validation_FirstName')),
    lastName: string().required(t('validation_LastName')),
    phoneNumber: string()
      .matches(regex, t('purchasePageFormValidationPhoneNumberBegin'))
      .matches(/^.{11,12}$/, t('purchasePageFormValidationPhoneNumberInvalid'))
      .required(t('validation_Mobile')),
    email: string()
      .email(t('validation_ValidEmail'))
      .matches(/^(?![^@]*([-.])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/, t('validation_ValidEmail'))
      .required(t('validation_Email')),
    terms: boolean().oneOf([true], t('purchasePageFormValidationTerms')),
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const options = {
    // we dont use this in testing environment, maybe we have to use this in production
    // passing the client secret obtained from the server
    clientSecret: `${process.env.GATSBY_VOZZI_STRIPE_SECRET_KEY}`,
  };

  const getLatOrLng: any = (type: 'lat' | 'lng') => {
    if (!geolocationPosition) {
      const geoLocation = { lat: _payslipUser.lat, lng: _payslipUser.lng };
      return geoLocation[type];
    } else {
      return geolocationPosition[type];
    }
  };

  const handleFirstStep = async (data: any) => {
    setUser({
      ...user,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      terms: data.terms,
    });

    const response = await axios.get(`/users/check-if-exists?email=${data.email}&mobileNumber=%2B${data.phoneNumber}`);

    // PaymentLog in CMS
    const params = {
      source: 'webshop',
      userFirstName: data.firstName,
      userLastName: data.lastName,
      userMobileNumber: data.phoneNumber,
      userEmail: data.email,
      bundleId: bundleDetails.bundle.id,
      checkoutId,
    };
    try {
      await axios.post('/payment-logs', params);
    } catch (e: any) {
      logError(`Payment log error. Error message: ${e.message || 'no err msg'}`, null, false);
    }

    if (!response.data.exists) {
      setBundle({
        bundleId: bundleDetails.bundle.id,
        bundleName: bundleDetails.bundle.attributes.name,
        bundlePrice: bundleDetails.bundle.attributes.price,
      });

      setPayslipUser({
        ..._payslipUser,
        userFirstName: data.firstName,
        userLastName: data.lastName,
        userMobileNumber: data.phoneNumber,
        userEmail: data.email,
      });

      setUser({
        ...user,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
      });

      if (weAreRunningInBrowser()) {
        localStorage.setItem(
          'allsecureData',
          JSON.stringify({
            user: {
              ...user,
              userFirstName: data.firstName,
              userLastName: data.lastName,
              userEmail: data.email,
              userMobileNumber: data.phoneNumber,
              lat: getLatOrLng('lat'),
              lng: getLatOrLng('lng'),
            },
            bundleId: bundleDetails.bundle.id,
          })
        );
      }

      gTagDataLayerPush({
        event: 'web_shop_1st_step_btn_click',
        country: COUNTRY,
        bundleKeyName: bundleDetails.bundleKeyName,
      });

      handleNext();
    } else {
      Dialog.fire({
        title: t('purchasePageUserAlreadyExistModalText'),
        icon: 'error',
        confirmButtonColor: theme.colors.vozziVividOrange,
      });
    }
  };

  const getInitialValues = () => {
    if (user.firstName) {
      return user;
    }

    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      terms: false,
      lat: null,
      lng: null,
    };
  };

  const createWithPayslip = async (user: any) => {
    user.lat = getLatOrLng('lat').toString();
    user.lng = getLatOrLng('lng').toString();
    if (weAreRunningInBrowser()) {
      if (localStorage.getItem('snnpNumber')) {
        user = {
          ...user,
          snnpCardNumber: localStorage.getItem('snnpNumber')!.replace(/\s/g, ''),
          snnpCardLevel: localStorage.getItem('snnpCardLevel'),
          snnpCardStatus: localStorage.getItem('snnpCardStatus'),
        };
      }
    }

    const response: any = await axios.post('client-bundles/with-new-client', user);

    if (response) {
      if (weAreRunningInBrowser()) {
        localStorage.removeItem('snnpNumber');
        localStorage.removeItem('snnpCardLevel');
        localStorage.removeItem('snnpCardStatus');
        localStorage.removeItem('bundleId');
        localStorage.removeItem('bundleKeyName');
        localStorage.removeItem('vozziApiCallbackUrl');
      }
      setPayslipUser(user);
      setPayslipUser({
        ...user,
        realBundleId: response.realId,
      });
      localStorage.removeItem('pibNumber');
      localStorage.setItem('realBundleId', response.realId);
      if (weAreRunningInBrowser()) {
        localStorage.setItem('payslipUser', JSON.stringify(user));
        localStorage.setItem('realBundleId', response.realId);
      }
      navigateTo('/sr/kupovina-uplatnica-kraj');
    }
  };

  const steps = [
    {
      label: t('purchasePageFirstStepTitle'),
      summary: `
        <div style="padding-left: 40px;padding-bottom: 10px;">
          <p>
            ${user.firstName} ${user.lastName}
          </p>
          <p>${user.phoneNumber}</p>
          <p>${user.email}</p>
        </div>
        <hr />
      `,
      description: t('purchasePageFirstStepDescription'),
      html: (
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          initialValues={getInitialValues()}
          onSubmit={(data: any) => handleFirstStep(data)}
          validationSchema={validationSchema}
        >
          {({ isValid, setFieldValue, values }) => (
            <Form style={{ paddingTop: 30, paddingLeft: 5 }}>
              <label style={{ display: 'block' }}>{t('purchasePageFormFirstNameLabel')}</label>
              <Field css={inputStyle} value={values.firstName} id="firstName" name="firstName" />
              <CustomErrorMessage name="firstName" />

              <label style={{ display: 'block' }}>{t('purchasePageFormLastNameLabel')}</label>
              <Field css={inputStyle} value={values.lastName} id="lastName" name="lastName" />
              <CustomErrorMessage name="lastName" />

              <label style={{ display: 'block' }}>{t('purchasePageFormMobileNumberLabel')}</label>
              <Field
                placeholder={t('purchasePageFormPhonePlaceholder')}
                css={inputStyle}
                value={values.phoneNumber}
                id="phoneNumber"
                name="phoneNumber"
              />
              <CustomErrorMessage name="phoneNumber" />

              <label style={{ display: 'block' }}>{t('purchasePageFormEmailLabel')}</label>
              <Field css={inputStyle} value={values.email} id="email" name="email" />
              <CustomErrorMessage name="email" />

              {COUNTRY === 'rs' ? (
                <>
                  <label
                    className="container-checkbox"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 12,
                      marginTop: 20,
                      borderRadius: '6px',
                    }}
                  >
                    <div
                      className="wpwl-wrapper wpwl-wrapper-custom container-checkbox"
                      style={{
                        height: '30px',
                        width: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '12px',
                        marginTop: '0px',
                        borderRadius: '6px',
                        marginRight: '0',
                      }}
                    >
                      <input
                        style={{ zIndex: 9999, height: '30px', width: '30px' }}
                        type="checkbox"
                        onChange={(e: any) => setLegalEntity(e.currentTarget.checked)}
                        id="legalEntity"
                      />
                      <span className="checkmark"></span>
                    </div>
                    <div
                      className="wpwl-label wpwl-label-custom"
                      style={{
                        display: 'inline-block',
                        color: '#3b3b3c',
                        textAlign: 'left',
                        marginBottom: '15px',
                        fontSize: 12,
                      }}
                    >
                      Pravno lice
                    </div>
                  </label>
                  {legalEntity ? (
                    <div className="pibNumberwrapper wpwl-control-cardNumber">
                      <div className="wpwl-wrapper wpwl-wrapper-custom" style={{ display: 'inline-block', marginBottom: '20px' }}>
                        <input
                          css={inputStyle}
                          onWheel={(e) => e.target.blur()}
                          className="wpwl-control wpwl-control-expiry"
                          type="number"
                          placeholder="PIB"
                          onKeyUp={(e: any) => {
                            localStorage.setItem('pibNumber', e.currentTarget.value);
                            setPibNumber(e.currentTarget.value);
                          }}
                          id="pibNumberPayslip"
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              <label
                className="container-checkbox"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 12,
                  marginTop: 20,
                  borderRadius: '6px',
                }}
              >
                <input
                  type="checkbox"
                  name="terms"
                  onChange={(e: any) => {
                    setFieldValue('terms', e.target.checked);
                  }}
                  checked={values.terms}
                  style={{ marginRight: 10, height: 20, width: 20 }}
                />
                <span className="checkmark"></span>
                <p className="checkbox-paragraph">
                  {t('purchasePageFormCheckboxLabel')}{' '}
                  <a href={t('link_termsAndConditions')} style={{ marginLeft: 5, color: 'inherit', textDecoration: 'underline' }}>
                    {t('purchasePageFormCheckboxLinkLabel')}
                  </a>
                </p>
              </label>
              <CustomErrorMessage name="terms" />

              <VozziPrimaryButton style={{ display: 'block', cursor: 'pointer' }} type="submit" mt={3}>
                {t('purchasePageFormSubmitButtonLabel')}
              </VozziPrimaryButton>
            </Form>
          )}
        </Formik>
      ),
    },
    {
      label: t('purchasePageSecondStepTitle'),
      summary: ``,
      description: '',
      html: (
        <Tabs
          style={{
            border: '1px solid #ddd',
            position: 'relative',
            left: '-30px',
            width: '100%',
            overflow: 'hidden',
          }}
          selectedIndex={tabIndex}
          onSelect={(index: any) => setTabIndex(index)}
          forceRenderTabPanel
        >
          <TabList style={{ display: 'none' }}>
            <Tab style={{ display: 'none' }} label="Item One" />
            {COUNTRY === 'rs' ? <Tab style={{ display: 'none' }} label="Item Two" /> : null}
          </TabList>
          <TabPanel forceRender={tabIndex === 0}>
            <div className="panel-content">
              <Tab
                onClick={() => setTabIndex(0)}
                style={{
                  padding: 20,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <label>
                  {COUNTRY === 'rs' ? (
                    <input
                      name="tab"
                      style={{ backgroundColor: tabIndex === 0 ? '#ff7f12' : 'white' }}
                      defaultChecked={tabIndex === 0}
                      type="radio"
                    />
                  ) : null}{' '}
                  {t('purchasePageCreditCardTabLabel')}
                </label>
                {COUNTRY === 'rs' ? <img style={{ height: '50px' }} src="/img/allsecure.webp" /> : null}
                {COUNTRY !== 'rs' ? <img style={{ height: '50px' }} className="stripe-logo" src="/img/powered-by-stripe-new.png" /> : null}
              </Tab>
              <div style={{ padding: 20 }}>
                {COUNTRY === 'rs' ? (
                  <AllSecureForm checkoutId={checkoutId!} bundleKeyName={bundleDetails?.bundle?.attributes.keyName ?? null} />
                ) : (
                  // pass options in production
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      user={{
                        userFirstName: user.firstName,
                        userLastName: user.lastName,
                        userMobileNumber: user.phoneNumber,
                        userEmail: user.email,
                        userAddress: 'Neka adresa',
                        userCity: 'Neki grad',
                        userPostalCode: '3333',
                        lat: getLatOrLng('lat'),
                        lng: getLatOrLng('lng'),
                      }}
                    />
                  </Elements>
                )}
              </div>
              {COUNTRY === 'rs' ? (
                <Tab onClick={() => setTabIndex(1)} style={{ borderTop: '1px solid #ddd', padding: 20, backgroundColor: 'white' }}>
                  <label>
                    <input
                      name="tab"
                      style={{ backgroundColor: tabIndex === 1 ? '#ff7f12' : 'white' }}
                      defaultChecked={tabIndex === 1}
                      type="radio"
                    />{' '}
                    {t('purchasePagePayslipTabLabel')}
                  </label>
                </Tab>
              ) : null}
            </div>
          </TabPanel>
          <TabPanel forceRender={tabIndex === 1}>
            <div className="panel-content">
              <Tab style={{ padding: 20, backgroundColor: 'white' }} onClick={() => setTabIndex(0)}>
                <label>
                  <input
                    name="tab"
                    style={{ backgroundColor: tabIndex === 0 ? '#ff7f12' : '' }}
                    defaultChecked={tabIndex === 0}
                    type="radio"
                  />{' '}
                  {t('purchasePageCreditCardTabLabel')}
                </label>
              </Tab>
              <Tab onClick={() => setTabIndex(1)} style={{ borderTop: '1px solid #ddd', padding: 20 }}>
                <label>
                  <input
                    name="tab"
                    style={{ backgroundColor: tabIndex === 1 ? '#ff7f12' : '' }}
                    defaultChecked={tabIndex === 1}
                    type="radio"
                  />
                  {t('purchasePagePayslipTabLabel')}
                </label>
              </Tab>
              <div style={{ padding: 20 }}>
                <VozziPrimaryButton
                  disabled={loading}
                  style={{
                    display: 'flex',
                    width: '100%',
                    cursor: loading ? 'disabled' : 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pointerEvents: loading ? 'none' : 'auto',
                    height: 50,
                  }}
                  onClick={() => {
                    setLoading(true);
                    gTagDataLayerPush({
                      event: 'web_shop_2nd_step_btn_payslip_click',
                      country: COUNTRY,
                      bundleKeyName: bundleDetails.bundleKeyName,
                    });
                    if (legalEntity) {
                      createWithPayslip({
                        buyerId: pibNumber,
                        userFirstName: user.firstName,
                        userLastName: user.lastName,
                        userMobileNumber: user.phoneNumber,
                        userEmail: user.email,
                        userAddress: 'Neka adresa',
                        userCity: 'Neki grad',
                        userPostalCode: '3333',
                        bundleId: bundleDetails.bundle.id,
                      });
                    } else {
                      createWithPayslip({
                        userFirstName: user.firstName,
                        userLastName: user.lastName,
                        userMobileNumber: user.phoneNumber,
                        userEmail: user.email,
                        userAddress: 'Neka adresa',
                        userCity: 'Neki grad',
                        userPostalCode: '3333',
                        bundleId: bundleDetails.bundle.id,
                      });
                    }
                  }}
                  mt={3}
                >
                  <img style={{ height: 40, visibility: loading ? 'visible' : 'hidden' }} src="/img/spinner-loader.svg" />
                  {t('purchasePagePayslipSendButtonLabel')}
                </VozziPrimaryButton>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      ),
    },
  ];

  if (COUNTRY === 'rs' && !checkoutId) {
    return <p>{loadingText}</p>;
  }

  if (!geolocationConfirmation) {
    return <p>{loadingText}</p>;
  }

  return (
    <Box sx={{ maxWidth: 700 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>
                  <span style={{ fontSize: '26px' }}>{step.label}</span>
                </h3>
                {index === 0 ? (
                  <button css={editButton} onClick={handleBack} style={{ visibility: activeStep === 1 ? 'visible' : 'hidden' }}>
                    {t('purchasePageFormEditButtonLabel')}
                  </button>
                ) : null}
              </div>
            </StepLabel>
            {activeStep === 1 ? <div dangerouslySetInnerHTML={{ __html: step.summary }}></div> : null}
            <StepContent className="multistepper-content">
              <div style={{ fontWeight: 300, paddingLeft: '8px' }} dangerouslySetInnerHTML={{ __html: step.description }}></div>
              {step.html}
              <Box sx={{ mb: 2 }}>
                <div></div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default MultiStepper;
