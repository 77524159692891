import './StripeFormLoader.css';
import { useTranslation } from 'react-i18next';

interface StripeFormLoaderProps {
  style?: any;
}

export const StripeFormLoader = (props: StripeFormLoaderProps) => {
  const { t, i18n } = useTranslation();
  props.style.color = 'rgb(59, 59, 60)';

  return (
    <div style={props.style}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
