import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Image } from 'rebass';
import { css } from '@emotion/core';
import { WebshopNewLayout } from '../../../layouts/WebshopNew.layout';
import { purchasePackage } from '../../../shared-state/main';
import { MdCheck } from 'react-icons/md';

import './purchase-page.css';
import { navigateTo } from '../../../components/functional/Navigate';

import MultiStepper from '../../../pages_/webshop/components/Stepper';
import { theme } from '../../../layouts/main.layout';
import ReactTooltip from 'react-tooltip';
import { weAreRunningInBrowser } from '../../../utils/weAreRunningInBrowser';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';
import { COUNTRY } from '../../../configs/env';
import { LanguageGuard } from '../../../components/layout/LanguageGuard/LanguageGuard';

const tooltipStyle = css`
  font-style: italic;
  text-align: center;
  width: 280px;
  border-radius: 10px !important;
  line-height: 1.15 !important;
  padding: 12px 10px !important;
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const PurchasePage = () => {
  const { t } = useTranslation();
  const bundleDetails = useRecoilValue(purchasePackage);
  const [snnpNumber, setSnnpNumber] = useState('7825 6827 ');
  const [goodSnnpNumber, setGoodSnnpNumber] = useState(false);
  const [warningSnnpMessage, setWarningSnnpMessage] = useState(false);
  const metaOgImg = `/img/meta-img/packages-webshop-meta-og-2-0-new.jpg`;

  useEffect(() => {
    if (weAreRunningInBrowser()) {
      gTagDataLayerPush({
        event: 'web_shop_1st_step',
        country: COUNTRY,
        bundleKeyName: bundleDetails.bundleKeyName,
      });
    }
  }, []);

  useLayoutEffect(() => {
    return () => {
      if (weAreRunningInBrowser()) {
        localStorage.removeItem('snnpNumber');
        localStorage.removeItem('snnpCardLevel');
        localStorage.removeItem('snnpCardStatus');
        localStorage.removeItem('allsecureData');
        localStorage.removeItem('bundleId');
        localStorage.removeItem('bundleKeyName');
        localStorage.removeItem('vozziApiCallbackUrl');
        localStorage.removeItem('payslipUser');
        localStorage.removeItem('realBundleId');
      }
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formatAndSetCcNumber = async (e: any) => {
    const inputVal = e.target.value.replace(/ /g, ''); //remove all the empty spaces in the input
    let inputNumbersOnly = inputVal.replace(/\D/g, ''); // Get only digits

    if (inputNumbersOnly.length > 16) {
      //If entered value has a length greater than 16 then take only the first 16 digits
      inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }
    // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = '';
    if (splits) {
      spacedNumber = splits.join(' '); // Join all the splits with an empty space
    }

    if (inputNumbersOnly.length < 9) {
      setSnnpNumber('7825 6827 ');
    } else {
      setSnnpNumber(spacedNumber);
      if (spacedNumber.length === 19) {
        const postData = { cardNumber: spacedNumber.replace(/\s/g, '') };
        const removedSpaces = spacedNumber.replace(/\s/g, '');

        await axios
          .post('/nis/validate-card-for-web-shop', postData)
          .then((response: any) => {
            setGoodSnnpNumber(true);
            setWarningSnnpMessage(false);
            if (weAreRunningInBrowser()) {
              localStorage.setItem('snnpNumber', removedSpaces);
              localStorage.setItem('snnpCardLevel', response.data.cardLevel);
              localStorage.setItem('snnpCardStatus', response.data.cardStatus);
            }
          })
          .catch((e) => {
            if (e.status === 'error') {
              setWarningSnnpMessage(true);
              setGoodSnnpNumber(false);
            }
          });
      }
    }

    if (spacedNumber.length < 19 && goodSnnpNumber) {
      setGoodSnnpNumber(false);
      setWarningSnnpMessage(false);
      if (weAreRunningInBrowser()) {
        localStorage.removeItem('snnpNumber');
        localStorage.removeItem('snnpCardLevel');
        localStorage.removeItem('snnpCardStatus');
      }
    }

    if (spacedNumber.length < 19 && warningSnnpMessage) {
      setWarningSnnpMessage(false);
      setGoodSnnpNumber(false);
    }
  };

  const meta = {
    title: t('meta_RoadsideAssistanceTitle'),
    description: t('meta_RoadsideAssistanceDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  if (bundleDetails.bundle === null && t('link_webShop')) {
    navigateTo(t('link_webShop'));
  }

  return (
    <WebshopNewLayout meta={meta} og={og}>
      <Container style={{ padding: '60px 0', backgroundColor: 'white' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <LanguageGuard visible={[{ country: 'rs' }]}>
            <div className="right-box-mobile" style={{ width: 'auto', display: 'none' }}>
              <div
                style={{
                  width: '275px',
                  height: 'auto',
                  padding: '15px 15px 15px 15px',
                  objectFit: 'contain',
                  borderRadius: '4px',
                  border: 'solid 0.5px #aeaeae',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <div>
                  <span
                    style={{
                      color: '#3b3b3c',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('purchasePageTotalBoxTitle')}
                  </span>
                </div>
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ color: '#3b3b3c' }}>{t('purchasePageTotalBoxItem')}</span>
                  <span>{bundleDetails.packageName}</span>
                </div>
                <hr style={{ borderBottom: 'solid 0.5px #aeaeae' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ color: '#3b3b3c' }}>{t('purchasePageTotalBoxPrice')}</span>
                  <span>
                    {bundleDetails.packagePrice} {bundleDetails.currency}
                  </span>
                </div>
              </div>
              {/*<div style={{ padding: '15px 0', width: '275px', position: 'relative' }}>*/}
              {/*  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>*/}
              {/*    <label style={{ fontWeight: 300, width: '85%', marginBottom: 10 }}>*/}
              {/*      Ukoliko imate SNNP karticu, unesite broj u polje pre kupovine VOZZi paketa dasdsdas*/}
              {/*    </label>*/}
              {/*    <ReactTooltip css={tooltipStyle} backgroundColor={theme.colors.white} textColor={theme.colors.darkGray} />*/}
              {/*    <p*/}
              {/*      data-tip={*/}
              {/*        'Kupovinom VOZZi paketa pomoći na putu, dobijate bonus poene na kartici “Sa nama na putu” koje možete iskoristiti za umanjenje računa prilikom kupovine goriva ili robe na svim NIS Petrol i Gazprom benzinskim stanicama.'*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Image src="/img/info-gray.svg" width="20px" />*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <input*/}
              {/*    style={{ width: '100%' }}*/}
              {/*    type="text"*/}
              {/*    value={snnpNumber}*/}
              {/*    id="snnpNumber"*/}
              {/*    onChange={(e: any) => {*/}
              {/*      const input: any = document.getElementById('snnpNumber');*/}
              {/*      const end: any = input.value.length;*/}
              {/*      input.setSelectionRange(end, end);*/}
              {/*      input.focus();*/}
              {/*      formatAndSetCcNumber(e);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*  {goodSnnpNumber ? (*/}
              {/*    <MdCheck*/}
              {/*      style={{*/}
              {/*        position: 'absolute',*/}
              {/*        bottom: '21px',*/}
              {/*        left: '198px',*/}
              {/*        color: 'green',*/}
              {/*        fontSize: '2.1em',*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  ) : null}*/}
              {/*  {warningSnnpMessage ? (*/}
              {/*    <p style={{ color: 'red' }}>*/}
              {/*      Kartica koju ste uneli nije važeća. Pokušajte ponovo. Ukoliko imate poteškoće sa unosom broja kartice, pozovite call*/}
              {/*      centar na 08 0000 8888.*/}
              {/*    </p>*/}
              {/*  ) : null}*/}
              {/*</div>*/}
            </div>
          </LanguageGuard>
        </div>
        <div className="purchase-page-wrapper" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="purchase-page-left-side" style={{ width: '70%' }}>
            <MultiStepper />
          </div>
          <div className="purchase-page-right-side" style={{ width: '30%' }}>
            <div
              style={{
                width: '275px',
                height: 'auto',
                margin: '29px 39px 20px 33px',
                padding: '15px 15px 15px 15px',
                objectFit: 'contain',
                borderRadius: '4px',
                border: 'solid 0.5px #aeaeae',
                backgroundColor: '#f9f9f9',
              }}
            >
              <div>
                <span
                  style={{
                    color: '#3b3b3c',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('purchasePageTotalBoxTitle')}
                </span>
              </div>
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: '#3b3b3c' }}>{t('purchasePageTotalBoxItem')}</span>
                <span>{bundleDetails.packageName}</span>
              </div>
              <hr style={{ borderBottom: 'solid 0.5px #aeaeae' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: '#3b3b3c' }}>{t('purchasePageTotalBoxPrice')}</span>
                <span>
                  {bundleDetails.packagePrice} {bundleDetails.currency}
                </span>
              </div>
            </div>

            {/*<LanguageGuard visible={[{ country: 'rs' }]}>*/}
            {/*  <div style={{ margin: '29px 39px 236.5px 33px', width: '275px', position: 'relative' }}>*/}
            {/*    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>*/}
            {/*      <label style={{ fontWeight: 300, width: '85%', marginBottom: 10 }}>*/}
            {/*        Ukoliko imate SNNP karticu, unesite 16-cifreni broj u polje pre kupovine VOZZi paketa*/}
            {/*      </label>*/}
            {/*      <ReactTooltip css={tooltipStyle} backgroundColor={theme.colors.white} textColor={theme.colors.darkGray} />*/}
            {/*      <p*/}
            {/*        data-tip={*/}
            {/*          'Kupovinom VOZZi paketa pomoći na putu, dobijate bonus poene na kartici “Sa nama na putu” koje možete iskoristiti za umanjenje računa prilikom kupovine goriva ili robe na svim NIS Petrol i Gazprom benzinskim stanicama.'*/}
            {/*        }*/}
            {/*      >*/}
            {/*        <Image src="/img/info-gray.svg" width="20px" />*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*    <input*/}
            {/*      style={{ width: '100%' }}*/}
            {/*      type="text"*/}
            {/*      value={snnpNumber}*/}
            {/*      id="snnpNumber"*/}
            {/*      onChange={(e: any) => {*/}
            {/*        const input: any = document.getElementById('snnpNumber');*/}
            {/*        const end: any = input.value.length;*/}
            {/*        input.setSelectionRange(end, end);*/}
            {/*        input.focus();*/}
            {/*        formatAndSetCcNumber(e);*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    {goodSnnpNumber ? (*/}
            {/*      <MdCheck*/}
            {/*        style={{*/}
            {/*          position: 'absolute',*/}
            {/*          bottom: '7px',*/}
            {/*          left: '213px',*/}
            {/*          color: 'green',*/}
            {/*          fontSize: '2.1em',*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    ) : null}*/}
            {/*    {warningSnnpMessage ? (*/}
            {/*      <p style={{ color: 'red' }}>*/}
            {/*        Kartica koju ste uneli nije važeća. Pokušajte ponovo. Ukoliko imate poteškoće sa unosom broja kartice, pozovite call*/}
            {/*        centar na 08 0000 8888.*/}
            {/*      </p>*/}
            {/*    ) : null}*/}
            {/*  </div>*/}
            {/*</LanguageGuard>*/}
          </div>
        </div>
      </Container>
    </WebshopNewLayout>
  );
};

export default PurchasePage;
