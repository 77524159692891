export const loadDynamicScript = (callback: (alreadyLoaded: boolean) => void, scriptId: string, scriptUrl: string) => {
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.id = scriptId;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback(false);
    };
  }

  if (existingScript && callback) callback(true);
};
