import * as React from 'react';
import './allsecurestyle.css';
import { loadDynamicScript } from './loadDynamicScript';
import { ALLSECURE_URL, COUNTRY } from '../../../configs/env';
import { logError } from '../services/logErrors';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';
import { VOZZiWindowI } from 'types/VOZZiWindow.interface';

declare const window: VOZZiWindowI;

interface AllSecureFormI {
  checkoutId: string;
  bundleKeyName: string | null;
}

const AllSecureForm = (props: AllSecureFormI) => {
  const [isValid, setIsValid] = React.useState(false);
  const { checkoutId } = props;

  React.useEffect(() => {
    try {
      loadDynamicScript(
        () => {
          loadDynamicScript(
            () => {
              loadDynamicScript(
                () => {
                  gTagDataLayerPush({
                    event: 'web_shop_2nd_step',
                    country: COUNTRY,
                    bundleKeyName: props.bundleKeyName!,
                  });
                },
                'allsecure',
                `${ALLSECURE_URL}/paymentWidgets.js?checkoutId=${checkoutId}`
              );
            },
            'wpwloptions',
            '/js/allsecurescript.js'
          );
        },
        'jquery',
        'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js'
      );
    } catch (err: any) {
      logError(`AllSecureFrom failed. Error: ${err.message || 'no err msg'}`, null, false, null, false);
    }

    setIsValid(true);

    return () => {
      if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
        window.wpwl.unload();
        $('script').each(function (this: any) {
          if (this.src.indexOf('script.js') !== -1) {
            $(this).remove();
          }
          if (this.src.indexOf('paymentWidgets.js') !== -1) {
            $(this).remove();
          }
        });
      }
    };
  }, []);

  return (
    <div style={{ display: isValid ? 'block' : 'none' }}>
      <form action="/sr/kupovina-kraj" className="paymentWidgets" data-brands="VISA MASTER MAESTRO"></form>
      <div className="credit-card-info" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-even' }}>
        <div className="text-center">
          <span className="payments-processed"></span>
        </div>
      </div>
    </div>
  );
};

export default AllSecureForm;
